.RecipesWindow {
  top: 8vh;
  left: 2vw;
  width: 75vw;
  height: 60vh;
}
.RecipesWindow table {
  width: 100%;
  border-collapse: collapse;
}
.RecipesWindow table td {
  text-align: center;
}
.RecipesWindow table thead tr {
  background-color: var(--recipesHeaderBg);
}
.RecipesWindow table tbody tr:nth-child(odd) {
  background-color: var(--recipesOddBg);
}
.RecipesWindow table tbody tr:nth-child(even) {
  background-color: var(--recipesEvenBg);
}
.RecipesWindow table tbody tr:hover {
  background-color: var(--orange);
  color: white;
}
.delete_recipe_button {
  display: inline-block;
  width: 2vh;
  height: 2vh;
  line-height: 2vh;
  background-color: darkred;
  color: white;
  border-radius: 50%;
}
.delete_recipe_button:hover {
  background-color: red;
}
