.LoginWindow {
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  position: absolute;
}
.computerStand {
  width: 30vw;
  height: 30vh;
  position: absolute;
  bottom: 1vh;
  left: calc(50% - 15vw);
  background: var(--calculatorBackground);
  border-radius: 50%;
  box-shadow: 0 0 10px black;
  z-index: 1;
}
.computer {
  width: 120vh;
  height: 90vh;
  background: var(--calculatorBackground);
  border-radius: 20px;
  position: relative;
  box-shadow: 0 0 10px black;
  z-index: 2;
  margin: auto;
}
.computerScreen {
  background: radial-gradient(
    circle,
    rgb(32 124 24) 0%,
    rgba(15, 75, 18, 1) 100%
  );
  width: 105vh;
  height: 75vh;
  position: absolute;
  top: 7.5vh;
  left: 7.5vh;
  border-radius: 10px;
  z-index: 3;
  color: var(--calculatorResultFg);
  font-size: 2em;
  text-align: center;
  font-weight: 100;
  overflow: hidden;
  text-shadow: 0 0 10px #e9e681;
  box-shadow: 0 0 10px #000 inset, 0 0 10px #000;
}
.computerScreen::after {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  --scanlinesColor: #0000001c;
  background: linear-gradient(
    to bottom,
    transparent 0%,
    transparent 16.66%,
    var(--scanlinesColor) 16.66%,
    var(--scanlinesColor) 33.32%,
    transparent 33.32%,
    transparent 49.98%,
    var(--scanlinesColor) 49.98%,
    var(--scanlinesColor) 66.64%,
    transparent 66.64%,
    transparent 83.3%,
    var(--scanlinesColor) 83.3%
  );
  background-size: 100% 5%;
  top: 0;
  position: absolute;
}
.computerScreenAnimated::before {
  content: " ";
  display: block;
  width: 100%;
  height: 5px;
  background-color: #0000001c;
  top: 0;
  opacity: 1;
  position: absolute;
  animation-name: scanlinesTravel;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-delay: 5s;
}

/* SCREEN CONTENT */
.computerScreenUsernamePrompt,
.computerScreenUsername,
.computerScreenPasswordPrompt,
.computerScreenPassword,
.computerScreenAccessGranted,
.computerScreenAccessGranted2 {
  font-family: "Pixelify Sans", sans-serif;
  font-size: 1em;
  position: absolute;
  width: 50%;
  text-align: left;
  left: 20%;
}
.computerScreenUsernamePrompt {
  top: 20vh;
}
.computerScreenUsername {
  top: 25vh;
}
.computerScreenPasswordPrompt {
  top: 35vh;
}
.computerScreenPassword {
  top: 39vh;
  width: 12.5%;
  left: 20%;
  background: transparent;
  border: none;
  color: var(--calculatorResultFg);
  border-bottom: 2px dashed var(--calculatorResultFg);
  z-index: 99;
}
.computerScreenPassword:focus {
  outline-width: 0;
}
.computerScreenAccessGranted {
  top: 50vh;
  background-clip: text;
  background-color: #00e71e;
  text-shadow: 0 0 10px #00e71e;
  color: transparent;
}
.computerScreenAccessGranted2 {
  top: 60vh;
}

/* DECORATIONS */
.computerDecorationSlotOne,
.computerDecorationSlotTwo {
  width: 1em;
  height: 1em;
  background-color: #000;
  border: 7px solid #555;
  box-shadow: 0 0 5px #000;
  position: absolute;
  bottom: 2vh;
  border-radius: 50%;
}
.computerDecorationSlotOne {
  left: 4vw;
}
.computerDecorationSlotTwo {
  left: 7vw;
}
.computerDecorationSpeakerOne,
.computerDecorationSpeakerTwo {
  width: 8em;
  height: 2em;
  background-image: radial-gradient(black 2px, transparent 0);
  background-size: 0.5em 0.5em;
  position: absolute;
  bottom: 2vh;
}
.computerDecorationSpeakerOne {
  left: 15vw;
}
.computerDecorationSpeakerTwo {
  right: 15vw;
}
.computerDecorationLed {
  width: 0.4em;
  height: 1.5em;
  position: absolute;
  bottom: 1.5em;
  right: 4vw;
  background-color: #9db33f;
  box-shadow: 0px 0px 6px #000, 0px 0px 2px #00000080 inset;
}
.computerDecorationFicsit {
  width: 100%;
  position: absolute;
  bottom: 2vh;
  left: 0;
  text-align: center;
  font-weight: 900;
  color: var(--calculatorDecorationFg);
  font-family: "Lexend";
  font-style: italic;
  font-size: 1.8em;
}
.computerDecorationPowerButton {
  width: 3em;
  height: 3em;
  background: var(--calculatorBackground);
  border-radius: 50%;
  box-shadow: 0 0 10px #000, 0 0 10px #000 inset;
  position: absolute;
  right: 5vw;
  bottom: 0.75em;
}

/* ANIMATIONS */
@keyframes scanlinesTravel {
  0% {
    top: 0;
  }
  25% {
    top: 100%;
    opacity: 1;
  }
  56% {
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 0;
  }
}
@keyframes ledBlink {
  0% {
    background-color: #3fb33f;
  }
  20% {
    background-color: #b46c00;
  }
  40% {
    background-color: #3fb33f;
  }
  60% {
    background-color: #b46c00;
  }
  80% {
    background-color: #3fb33f;
  }
  100% {
    background-color: #3fb33f;
  }
}
