.HomeWindow {
  top: 10vh;
  left: 25vw;
  width: 50vw;
  height: 85vh;
}
.os {
  width: 25vh;
  height: 25vh;
  background-color: #00000060;
  border-radius: 1em;
  color: var(--orange);
  font-size: 2em;
  text-align: center;
  margin: 2vh auto 3vh;
  font-weight: 100;
}
.os img {
  width: 15vh;
  height: 15vh;
  display: block;
  margin: auto;
}
.settings {
  width: 100%;
  height: 51vh;
  overflow-y: scroll;
  background-color: var(--settingsSectionBg);
}
.settings h2 {
  padding-left: 2vw;
}
.settings section {
  margin-bottom: 4vh;
}
.HomeWindow label {
  display: block;
  margin: 1vh auto;
  text-align: center;
}
.settingsButton,
.HomeWindow select,
.HomeWindow input {
  background-color: var(--settingsInputBg);
  color: var(--settingsInputFg);
  padding: 10px;
  margin-left: 1vw;
  border: 1px solid var(--settingsInputBorder);
}
.settingsButton:hover,
.HomeWindow select:hover,
.HomeWindow input:hover {
  border-color: var(--settingsInputFg);
}
input[type="checkbox"] {
  appearance: none;
  width: 1.5em;
  height: 1.5em;
  position: relative;
}

input[type="checkbox"]::before {
  content: "✔️";
  background-clip: text;
  background-color: var(--settingsInputFg);
  color: transparent;
  width: 1.5em;
  height: 0em;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  transition: 0.5s height ease-in-out;
}

input[type="checkbox"]:checked::before {
  height: 1.5em;
}
