.CodexWindow {
  top: 10vh;
  left: 10vw;
  width: 80vw;
  height: 80vh;
}
.CodexMain {
  position: relative;
}
.codexNav,
.codexContent {
  position: absolute;
  top: 5vh;
  height: calc(100% - 5vh);
}
.codexNav {
  left: 0;
  width: 10vw;
  border-right: 4px double #999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.codexContent {
  left: 10vw;
  width: 70vw;
  overflow-y: scroll;
}
.codexNavLink {
  font-weight: bold;
}
.codexNavLink:hover {
  text-decoration: underline;
}
.activeNavLink {
  font-weight: bold;
  color: var(--orange);
}
.section {
  width: 90%;
  margin: 5vh auto;
}
.activeSection > h2 {
  color: var(--textColorDefault);
  animation-name: textBlink;
  animation-duration: 1s;
  animation-iteration-count: 3;
}

@keyframes textBlink {
  0% {
    color: var(--textColorDefault);
  }
  50% {
    color: var(--orange);
  }
  100% {
    color: var(--textColorDefault);
  }
}
