.BuildingNode {
  border-radius: 10px;
  border: 1px solid var(--factoryNodeBorder);
  width: 140px;
  height: 80px;
  text-align: center;
  position: relative;
  background-color: var(--factoryNodeBg);
}
.BuildingNode::before {
  --bgSize: 15px;
  --arrowSize: calc(var(--bgSize) / 2);
  --bgOrigin: 61px;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: var(--bgSize);
  height: 140px;
  background: conic-gradient(
      var(--factoryNodeBg) 0 0.125turn,
      transparent 0.126turn 0.874turn,
      var(--factoryNodeBg) 0.875turn 1turn
    ),
    conic-gradient(
        transparent 0 0.375turn,
        var(--factoryNodeBg) 0.376turn 0.624turn,
        transparent 0.625turn 1turn
      )
      var(--arrowSize) 0.8px,
    var(--factoryNodeArrow);
  background-size: var(--bgSize) var(--bgSize);
  transform: rotate(270deg);
  transform-origin: var(--bgOrigin) var(--bgOrigin);
}
.BuildingNodeLabel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2em;
  line-height: 2em;
  text-align: center;
  display: block;
  font-size: 0.7em;
  background: var(--orangeMidDark);
  color: white;
  border-radius: 10px 10px 0 0;
}
.BuildingNodeLabelButton {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  width: 2em;
  height: 2em;
  line-height: 2em;
  text-align: center;
  display: block;
  font-size: 0.5em;
  background: var(--factoryNodeBg);
  color: var(--factoryNodeBorder);
  border-radius: 50%;
  cursor: pointer;
}
.slotIn,
.slotOut {
  --slotSize: 20px;
  display: inline-block;
  border: 1px solid var(--factoryNodeSlotBorder);
  background-color: var(--factoryNodeSlotBg);
  width: var(--slotSize);
  height: var(--slotSize);
  border-radius: 5px;

  position: relative;
}
.slotQty {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: var(--orange);
  border-top-left-radius: 5px;
  color: white;
  font-size: 0.5em;
}
.inputHandles,
.outputHandles {
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.inputHandles {
  left: 0;
}

.outputHandles {
  right: 0;
}
.inputSlots,
.outputSlots {
  background: var(--factoryNodeSlotContainerBg);
  width: 60px;
  height: 55px;
  bottom: 1px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: absolute;
  justify-content: space-around;
  box-shadow: 2px 2px 5px #252525 inset;
  border-radius: 10px;
  align-content: space-around;
}
.inputSlots {
  left: 5px;
}
.outputSlots {
  right: 5px;
}
.outOnly > .outputSlots {
  right: 40px;
}
.inOnly > .inputSlots {
  left: 40px;
}
