.buildingPanelBlur {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  backdrop-filter: blur(10px);
}
.buildingPanel {
  z-index: 999;
  width: 85vw;
  height: 60vh;
  position: fixed;
  left: 7vw;
  top: 25vh;
  background-color: var(--editNodeTitlebarBg);
  box-shadow: 0px 0px 50px black;
  border-radius: 10px;
  overflow: hidden;
}
.panelTitle {
  background-color: var(--editNodeTitlebarTabBg);
  width: 25%;
  height: 5vh;
  line-height: 5vh;
  display: block;
  font-size: 1.2em;
  margin-left: 25px;
  padding-left: 10px;
}
.buildingPanelContent {
  background: linear-gradient(
    to bottom,
    var(--editNodeGradient1),
    var(--editNodeGradient2)
  );
  width: 100%;
  height: 55vh;
  position: absolute;
  left: 0;
  top: 5vh;
}
.closePanel {
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  width: 75px;
  height: 5vh;
  line-height: 5vh;
  background-color: var(--editNodeTitlebarTabBg);
  text-align: center;
}
.closePanel:hover {
  background-color: var(--orange);
  color: white;
}
.buildingHeader {
  position: absolute;
  width: 100%;
  height: 5vh;
  top: 1vh;
  z-index: 8;
}
.building_recipe_select {
  width: 50%;
  display: block;
  background: var(--editNodeRecipeSelectBg);
  color: var(--editNodeRecipeSelectFg);
  height: 5vh;
  text-align: center;
  font-family: "Quicksand";
  margin: auto;
  font-weight: bold;
}
.building_recipe_select:hover {
  border-color: var(--orange);
}
.building_recipe {
  display: flex;
  flex-wrap: nowrap;
  background: #00000040;
  justify-content: space-evenly;
  width: 90%;
  height: 15vh;
  border-radius: 1em;
  align-items: center;
  position: absolute;
  left: 5%;
  top: 7vh;
}

.building_recipe_part,
.building_recipe_result,
.building_recipe_arrow {
  display: inline-flex;
  background-color: var(--editNodeSlotBg);
  width: 8em;
  height: 6em;
  text-align: center;
  border-radius: 1em;
  flex-direction: column;
  justify-content: center;
}
.building_recipe_part:hover,
.building_recipe_result:hover {
  background-color: var(--editNodeSlotBgHover);
}
.building_recipe_arrow {
  background: var(--calculatorBackground);
  width: 3em;
  height: 3em;
  border-radius: 50%;
  border-bottom: 5px solid var(--bodyBackground1);
}

.building_recipe_result {
  border: 2px solid var(--orangeBorder);
}
.building_recipe_result_quantity,
.building_recipe_part_quantity {
  color: var(--orange);
}

.building_boosts_clock,
.building_boosts_somersloop {
  position: absolute;
  height: 20vh;
  bottom: 8vh;
}
.building_boosts_clock_decoration100,
.building_boosts_clock_decoration150,
.building_boosts_clock_decoration200 {
  width: 1px;
  background-color: var(--orange);
  height: 100%;
  position: absolute;
}
.building_boosts_clock_decoration100 {
  left: 16.2vw;
}
.building_boosts_clock_decoration150 {
  left: 23.8vw;
}
.building_boosts_clock_decoration200 {
  left: 31.4vw;
}
.building_boosts_clock {
  width: 40vw;
  background: linear-gradient(
    to bottom,
    var(--editNodeClockGradient1),
    var(--editNodeClockGradient2)
  );
  border: 5px solid var(--editNodeBoostsBorder);
  border-radius: 1em;
  left: 10vw;
}
.building_boosts_clock_label,
.building_boosts_somersloop_label {
  position: absolute;
  bottom: 4vh;
  width: 12vw;
  height: 3vh;
  line-height: 3vh;
  background: #ddd;
  border-radius: 10px;
  color: #666;
  font-weight: bolder;
  text-align: center;
}
.building_boosts_clock_label {
  left: 24.5vw;
}
.building_boosts_clock_label::after,
.building_boosts_somersloop_label::after {
  content: " ";
  width: 5px;
  height: 1vh;
  background-color: #ddd;
  display: block;
  position: absolute;
  bottom: 100%;
}
.building_boosts_clock_label::after {
  left: 6vw;
}
.building_boosts_somersloop_label {
  right: 12.3vw;
}
.building_boosts_somersloop_label::after {
  left: 6vw;
}
.building_boosts_clock_title,
.building_boosts_clock_target_title {
  position: absolute;
  left: 1em;
  font-weight: 600;
}
.building_boosts_clock_title {
  top: 0;
}
.building_boosts_clock_target_title {
  top: 8.5vh;
}
.ocvalue,
.targetInput {
  position: absolute;
  left: 1vw;
  width: 4em;
  height: 4vh;
  background-color: var(--editNodeControlsBg);
  color: var(--orange);
  border: none;
  padding: 0;
  text-align: center;
  font-size: 1.2em;
  font-family: Quicksand;
}
.ocvalue {
  top: 3vh;
}
.targetInput {
  top: 11.5vh;
}
.ocvalueafter,
.targetInputAfter {
  position: absolute;
  height: 5vh;
  left: calc(2vw + 4em);
  line-height: 5vh;
  font-size: 1.2em;
}
.ocvalueafter {
  top: 2.5vh;
  color: var(--orange);
}
.targetInputAfter {
  top: 11vh;
}
.ocSlider {
  position: absolute;
  bottom: 1vh;
  left: 1vw;
  appearance: none;
  width: calc(100% - 2vw);
  height: 2vh;
  border-radius: 5px;
  outline: none;
}
.ocSlider::-webkit-slider-thumb {
  appearance: none;
  width: 0;
  height: 0;
  background: linear-gradient(to right, var(--orange), #fff57a);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.powerShardIcon,
.powerShardIconGreyed {
  position: absolute;
  top: 5vh;
  width: 2.5vw;
  height: 2.5vw;
  padding: 1vw;
  background: var(--editNodeControlsBg);
  border-radius: 1em;
}
.powerShardIconGreyed {
  filter: grayscale(100%);
}
.powerShardIcon[data-key="0"],
.powerShardIconGreyed[data-key="0"] {
  left: 17.75vw;
}
.powerShardIcon[data-key="1"],
.powerShardIconGreyed[data-key="1"] {
  left: 25.35vw;
}
.powerShardIcon[data-key="2"],
.powerShardIconGreyed[data-key="2"] {
  left: 33.5vw;
}
.building_boosts_somersloop {
  right: 12vw;
  width: 12vw;
  background: linear-gradient(
    to bottom,
    #664466,
    var(--editNodeClockGradient2)
  );
  border-radius: 1em;
  border: 5px solid var(--editNodeBoostsBorder);
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}

.building_boosts_title {
  text-align: center;
  margin: 1vh 0;
  font-weight: bold;
}
.somersloopInput {
  background-color: var(--inputBg);
  color: var(--inputFg);
  border: 1px solid var(--inputBorder);
  padding: 0;
  font-size: 1.5em;
  text-align: center;
}
.somersloopIcon {
  width: 48px;
  height: 48px;
}
.copySettingsButton,
.pasteSettingsButton {
  display: block;
  width: 150px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  position: absolute;
  bottom: 15px;
  background: linear-gradient(
    var(--editNodeCopyPasteGradient1),
    var(--editNodeCopyPasteGradient2)
  );
  box-shadow: 0px 4px 5px black;
  color: var(--editNodeCopyPasteFg);
  font-weight: 100;
}
.copySettingsButton:hover,
.pasteSettingsButton:hover {
  background: linear-gradient(
    var(--editNodeCopyPasteGradient2),
    var(--editNodeCopyPasteGradient1)
  );
}
.copySettingsButton {
  left: 15px;
}
.pasteSettingsButton {
  left: 175px;
}
.somersloopMultiplier {
  color: #d300d3;
  font-weight: bold;
  position: absolute;
  bottom: 5px;
  font-size: 1.5em;
}
.buildingPanelErrorMessage {
  position: absolute;
  top: 10vh;
  left: 25vw;
  width: 50vw;
  height: 3em;
  background-color: #00000087;
  border: 1px solid #d5b900;
  color: #d5b900;
  border-radius: 20px;
  padding: 1em;
  font-weight: bold;
  white-space: pre-line;
}
.copyPasteMessage {
  position: absolute;
  bottom: 5vh;
  left: 35vw;
  width: 30vw;
  height: 2em;
  line-height: 2em;
  background-color: #00000087;
  color: #41d513;
  border-radius: 20px;
  padding: 1em;
  text-align: center;
}
