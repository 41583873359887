:root {
  /* Common */
  --orangeBorder: #99673a;
  --orange: #fa9549;
  --orangeMidDark: #e78e48;
  --orangeDark: #d75f05;

  --editNodeCopyPasteGradient1: #343434;
  --editNodeCopyPasteGradient2: #252525;
  --editNodeCopyPasteFg: #fcfcfc;

  /*Theme (default:dark) */
  --textColorDefault: #ccc;
  --scrollbarBg: #222;
  --scrollbarFg: #696969;
  --bodyBackground1: #161313;
  --bodyBackground2: #1f1a1a;
  --menuBg: #333;
  --menuButtonBg: #444;
  --menuButtonFg: #fff;
  --windowTitlebarBg: #222;
  --windowButtonBg: #444;
  --windowBg: #333;
  --windowButtonBgHover: #555;
  --inputBg: #444;
  --inputFg: #aaa;
  --inputBorder: #999;

  --contextMenuBg: #333;
  --contextMenuBgHover: #555;
  --contextMenuBorder: #666;
  --contextMenuFg: #fff;
  --contextMenuEndsBg: #222;
  --contextMenuEndsFg: #666;

  --recipesHeaderBg: #111;
  --recipesOddBg: #444;
  --recipesEvenBg: #555;

  --settingsSectionBg: #2b2b2b;
  --settingsInputBg: #222;
  --settingsInputFg: #ccc;
  --settingsInputBorder: #767676;

  --codexEntryBg: #444;

  --factoryNodeBg: #333;
  --factoryNodeBorder: #ddd;
  --factoryNodeArrow: #1c1c1c;
  --factoryNodeSlotContainerBg: #444;
  --factoryNodeSlotBg: #666;
  --factoryNodeSlotBorder: #999;
  --factoryMenuBg: #222;

  --editNodeGradient1: #646464;
  --editNodeGradient2: #444;
  --editNodeSlotBg: #222;
  --editNodeSlotBgHover: #444;
  --editNodeClockGradient1: #666;
  --editNodeClockGradient2: #444;
  --editNodeBoostsBorder: #333;
  --editNodeTitlebarBg: #3f3f3f;
  --editNodeTitlebarTabBg: #565656;
  --editNodeRecipeSelectBg: #191919;
  --editNodeRecipeSelectFg: #fff;
  --editNodeRecipeSelectBorder: #ccc;
  --editNodeRecipeSelectOptionBg: #393939;
  --editNodeRecipeSelectOptionBgHover: #494949;
  --editNodeRecipeSelectCounterBg: #555;
  --editNodeControlsBg: #777;

  --sheetEmptyCell: #333;
  --sheetFicsitFg: #3a3a3a;
  --sheetBg: #434343;

  --calculatorBg: #333;
  --calculatorBackground: linear-gradient(to bottom, #777, #333);
  --calculatorBgBox: #414141;
  --calculatorResultBg: #0f4b12;
  --calculatorResultFg: #ffd44b;
  --calculatorResultBorder: #3e673f;
  --calculatorResultBorderAlt: #455726;
  --calculatorPanelBg: #111;
  --calculatorPanelFg: #eee;
  --calculatorCategoryBackground: #333;
  --calculatorButtonBg: #afafaf;
  --calculatorButtonBoxBg: #646464;
  --calculatorButtonFg: #000000;
  --calculatorButtonOpBg: #676767;
  --calculatorButtonOpFg: #fff;
  --calculatorDecorationBg: #555;
  --calculatorDecorationFg: #444;
  --calculatorCustomOpBg: #cd996a;
  --calculatorPaperGradient1: #666;
  --calculatorPaperGradient2: #444;
  --calculatorPaperFg: #ccc;
}

/* Light theme */
[data-theme="light"] {
  --textColorDefault: #000;
  --scrollbarBg: #b6b6b6;
  --scrollbarFg: #fa9549;
  --bodyBackground1: #dfdfdf;
  --bodyBackground2: #e7e7e7;
  --menuBg: #ccc;
  --menuButtonBg: #bbb;
  --menuButtonFg: #000;
  --windowTitlebarBg: #bbb;
  --windowButtonBg: #aaa;
  --windowBg: #ddd;
  --windowButtonBgHover: #999;
  --inputBg: #ccc;
  --inputFg: #000;
  --inputBorder: #777;

  --contextMenuBg: #ddd;
  --contextMenuBgHover: #bbb;
  --contextMenuBorder: #666;
  --contextMenuFg: #000;
  --contextMenuEndsBg: #aaa;
  --contextMenuEndsFg: #666;

  --recipesHeaderBg: #999;
  --recipesOddBg: #aaa;
  --recipesEvenBg: #bbb;

  --settingsSectionBg: #ccc;
  --settingsInputBg: #aaa;
  --settingsInputFg: #000;
  --settingsInputBorder: #888;

  --codexEntryBg: antiquewhite;

  --factoryNodeBg: #ccc;
  --factoryNodeBorder: #444;
  --factoryNodeArrow: #aaa;
  --factoryNodeSlotContainerBg: #ddd;
  --factoryNodeSlotBg: #999;
  --factoryNodeSlotBorder: #666;
  --factoryMenuBg: #999;

  --editNodeGradient1: #ddd;
  --editNodeGradient2: #ccc;
  --editNodeSlotBg: #eee;
  --editNodeSlotBgHover: #777;
  --editNodeClockGradient1: #ddd;
  --editNodeClockGradient2: #aaa;
  --editNodeBoostsBorder: #999;
  --editNodeTitlebarBg: #bbb;
  --editNodeTitlebarTabBg: #aaa;
  --editNodeRecipeSelectBg: antiquewhite;
  --editNodeRecipeSelectFg: #555;
  --editNodeRecipeSelectBorder: #ccc;
  --editNodeRecipeSelectOptionBg: #ddd;
  --editNodeRecipeSelectOptionBgHover: #eee;
  --editNodeRecipeSelectCounterBg: #ccc;
  --editNodeControlsBg: #eee;

  --sheetEmptyCell: #999;
  --sheetFicsitFg: #cacaca;
  --sheetBg: #ddd;

  --calculatorBg: antiquewhite;
  --calculatorBackground: linear-gradient(to bottom, antiquewhite, #d1c4b3);
  --calculatorBgBox: #615c57;
  --calculatorResultBg: #0f4b12;
  --calculatorResultFg: #ffd44b;
  --calculatorResultBorder: #3e673f;
  --calculatorResultBorderAlt: #455726;
  --calculatorPanelBg: #ccc;
  --calculatorPanelFg: #000;
  --calculatorCategoryBackground: #c7bdb0;
  --calculatorButtonBg: #fff0de;
  --calculatorButtonBoxBg: #646464;
  --calculatorButtonFg: #000;
  --calculatorButtonOpBg: #999;
  --calculatorButtonOpFg: #fff;
  --calculatorDecorationBg: #d7bb95;
  --calculatorDecorationFg: #7d756b;
  --calculatorCustomOpBg: #cd996a;
  --calculatorPaperGradient1: #fafaf9;
  --calculatorPaperGradient2: #faf6e4;
  --calculatorPaperFg: #000;
}

@font-face {
  font-family: "Lexend";
  src: url("/public/fonts/Lexend.ttf") format("truetype");
  font-weight: 100 900;
}

@font-face {
  font-family: "Quicksand";
  src: url("/public/fonts/Quicksand.ttf") format("truetype");
  font-weight: 300 700;
}
@font-face {
  font-family: "Pixelify Sans";
  src: url("/public/fonts/PixelifySans.ttf") format("truetype");
  font-weight: 300 700;
}
.App {
  color: var(--textColorDefault);
  width: 100vw;
  height: 100vh;
  font-family: "Quicksand", sans-serif;
  cursor: default;
  cursor: url("../public/img/cursor.png"), auto;
  background: repeating-linear-gradient(
    135deg,
    var(--bodyBackground1),
    var(--bodyBackground1) 20px,
    var(--bodyBackground2) 20px,
    var(--bodyBackground2) 40px
  );
}
.menu {
  width: 100vw;
  height: 5vh;
  line-height: 5vh;
  position: fixed;
  background: var(--menuBg);
  z-index: 8;
  display: flex;
}
.menuIcon {
  width: 5vh;
  height: 5vh;
  margin-right: 1vw;
}
.menu span {
  background-color: var(--menuButtonBg);
  height: 5vh;
  padding: 0 10px;
  color: var(--menuButtonFg);
  transition: background-color 0.3s linear;
  display: inline-flex;
  margin: 0 1vw;
}
.menu span:hover {
  background-color: var(--orange);
}
.menu .active {
  background-color: var(--orange);
}
.window_active {
  z-index: 9;
}
::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: var(--scrollbarBg);
  border: none;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  border: 2px solid transparent;
  background-clip: content-box;
  background-color: var(--scrollbarFg);
}
