.selectContainer {
  width: 50%;
  margin: auto;
}
.selectDisplay {
  background: var(--editNodeRecipeSelectBg);
  color: var(--editNodeRecipeSelectFg);
  height: 5vh;
  line-height: 5vh;
  text-align: center;
  font-family: "Quicksand";
  font-weight: bold;
  border: 1px solid var(--editNodeRecipeSelectBorder);
}
.selectDisplay:hover {
  border-color: var(--orange);
}
.selectDisplay:hover > .selectLabel {
  opacity: 0;
}
.selectLabel {
  position: absolute;
  top: calc(50% - 1.5em);
  left: 27%;
  font-weight: 500;
  color: var(--orange);
  font-size: 0.9em;
  opacity: 1;
  transition: opacity 1s ease-out;
}
.selectArrow {
  float: right;
  margin-right: 10px;
}

.selectOptions {
  position: absolute;
  top: 100%;
  left: 25%;
  background: var(--editNodeRecipeSelectOptionBg);
  padding: 0;
  margin: 0;
  z-index: 1;
  width: calc(50% - 2px);
  max-height: 50vh;
  overflow-y: scroll;
  list-style-type: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.selectOption {
  padding: 10px;
}

.selectOption:hover {
  background: var(--editNodeRecipeSelectOptionBgHover);
}
.selectOptionCounter {
  width: 2em;
  height: 2em;
  line-height: 2em;
  text-align: center;
  background-color: var(--editNodeRecipeSelectCounterBg);
  border-radius: 50%;
  display: inline-block;
}
