.window_basic {
  position: absolute;
  background-color: var(--windowBg);
  border-radius: 7px;
  box-shadow: 0px 0px 20px #000;
  overflow: hidden;
}
.window_titlebar {
  width: 100%;
  height: 4vh;
  line-height: 4vh;
  text-align: center;
  position: relative;
  background-color: var(--windowTitlebarBg);
}
.window_titlebar .closewindowbutton,
.window_titlebar .titlebarbutton {
  display: inline-block;
  height: 4vh;
  position: absolute;
  background-color: var(--windowButtonBg);
}
.window_titlebar .closewindowbutton {
  right: 0;
  width: 4vh;
  font-weight: bold;
  font-size: 22px;
}
.window_titlebar .titlebarbutton {
  padding: 0 10px;
  left: 25px;
}
.window_titlebar .titlebarbutton:hover,
.window_titlebar .closewindowbutton:hover {
  background-color: var(--windowButtonBgHover);
}
