.contextMenu {
  position: absolute;
  background: var(--contextMenuBg);
  box-shadow: 0 0px 10px black;
  z-index: 1000;
  width: 25vw;
  border-radius: 1em;
  border: 1px solid var(--contextMenuBg);
  color: var(--contextMenuFg);
  right: 1vw;
  top: 2vh;
}

.menuItem {
  height: 5vh;
  line-height: 5vh;
  padding-left: 1vw;
}

.menuItem:hover {
  background-color: var(--contextMenuBgHover);
}
.contextMenuHeader {
  height: 25px;
  background-color: var(--contextMenuEndsBg);
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
  display: flex;
}
.contextMenuHeaderCheckit {
  height: 22px;
  width: 22px;
  position: absolute;
  left: 22px;
}
.contextMenuHeaderFicsit {
  height: 22px;
  width: 88px;
  position: absolute;
  left: calc(50% - 44px);
}
.contextMenuFooter {
  height: 2vh;
  line-height: 2vh;
  color: var(--contextMenuEndsFg);
  text-align: center;
  font-size: 2em;
  background-color: var(--contextMenuEndsBg);
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
}
