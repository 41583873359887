.fabContainer {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.fab {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--factoryMenuBg);
  color: #fff;
  font-size: 24px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s;
}

.fab.open {
  transform: rotate(45deg);
}

.menu {
  position: absolute;
  bottom: 70px;
  left: -17px;
  background-color: var(--factoryMenuBg);
  border-radius: 10px;
  width: 90px;
  height: 83vh;
  overflow: hidden scroll;
}
.menu::-webkit-scrollbar {
  width: 8px;
}
.menu::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: transparent;
  border: none;
}
.menu::-webkit-scrollbar-thumb {
  border-radius: 4px;
  border: 2px solid transparent;
  background-clip: content-box;
  background-color: var(--orange);
}

.menuItem {
  width: 76px;
  height: 76px;
  background-color: #555;
  color: #fff;
  border-radius: 14%;
  display: block;
  margin: 5px auto;
  transition: background-color 0.3s;
  text-align: center;
  position: relative;
}
.menuItem:hover {
  background-color: var(--orange);
}
.menuItemLabel {
  font-size: 0.7em;
  width: 66px;
  display: block;
  overflow: hidden;
  height: 1.5em;
  text-overflow: ellipsis;
  position: absolute;
  bottom: 5px;
  left: 4px;
  word-break: break-all;
}
.menuItem img {
  width: 45px;
  height: 45px;
  display: block;
  position: absolute;
  top: 5px;
  left: 14px;
}

.menuIcon {
  width: 42px;
  height: 42px;
}

@keyframes dash-animation {
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: 20;
  }
}

.animatedDashEdge {
  stroke-dasharray: 5, 5; /* Defines dash and gap lengths */
  animation: dash-animation 1s linear infinite;
}
