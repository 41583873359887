.SheetWindow {
  top: 7.5vh;
  left: 2.5vw;
  width: 95vw;
  height: 90vh;
  overflow: hidden;
}
.buildingsList {
  width: 100%;
  height: calc(100% - 4vh);
  overflow-y: scroll;
  background-color: var(--sheetBg);
  overflow-x: hidden;
}
.boostIcon {
  width: 1.5em;
  height: 1.5em;
}
.factoryTable {
  width: 80%;
  text-align: center;
  margin: auto;
  border-collapse: collapse;
}
.factoryTableDecorationLeft,
.factoryTableDecorationRight {
  font-family: "Lexend", sans-serif;
  color: var(--sheetFicsitFg);
  font-size: 4em;
  writing-mode: tb;
  position: absolute;
  top: 4vh;
  width: 8%;
  height: 86vh;
  line-height: calc(6.5vw);
  font-weight: bold;
  text-align: center;
}
.factoryTableDecorationLeft {
  left: 3vw;
  rotate: 180deg;
  border-right: 1px dashed black;
}
.factoryTableDecorationRight {
  right: calc(10px + 3vw);
  border-right: 1px dashed black;
}
.factoryTableDecorationLeft::before,
.factoryTableDecorationRight::after {
  content: " ";
  position: absolute;
  top: 0px;
  right: -3vw;
  width: calc(3vh + 10px);
  height: 86vh;
  background-color: transparent;

  /* Circle pattern only in the top 20% */
  background-image: radial-gradient(circle, black 20%, transparent 20%),
    linear-gradient(transparent 0%, transparent 20%);

  /* Apply circle pattern only at the top */
  background-position: 0 0, 0 0;

  /* Repeat the circles only in the 20% area */
  background-repeat: repeat, no-repeat;

  /* Restrict the radial gradient to the top 20% */
  background-size: calc(3vh + 10px) calc(3vh + 10px);
}

.green1 {
  background-color: #263d19;
  color: #ffd966;
}
.green2 {
  background-color: #274e13;
  color: #ffd966;
}
.green3 {
  background-color: #38761d;
  color: #ffe599;
}
.green4 {
  /*background-color: #6aa84f;*/
  background-color: #609b46;
  color: #fff2cc;
}
.buildingName {
  text-align: left;
  padding-left: 5vw;
}
.categoryHead {
  font-size: 26px;
}
.categorySubhead {
  font-size: 20px;
}
.categoryBody {
  font-size: 16px;
}

.factoryTable td {
  border: 1px solid black;
  border-spacing: 0;
}
.buildingCount {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5em;
  background-color: #38761d;
  border-radius: 50%;
  font-size: 0.7em;
  text-align: center;
  margin-right: 1vw;
}
.partIcon {
  margin-left: 5px;
}
.targetIcon {
  margin-right: 10px;
}
.partTotal {
  display: block;
  border-bottom: 1px solid black;
}
.partTotal:last-of-type {
  border-bottom: none;
}
.partTotal:nth-child(n) {
  background: #38761d;
}
.partTotal:nth-child(2n) {
  background: #609b46;
}
@keyframes circle {
  0% {
    border-color: orange gold gold gold;
  }
  25% {
    border-color: gold orange gold gold;
  }
  50% {
    border-color: gold gold orange gold;
  }
  75% {
    border-color: gold gold gold orange;
  }
  100% {
    border-color: orange gold gold gold;
  }
}

.targetPartTotal {
  border: 2px solid gold;
}
.targetPartTotalAnimated {
  border: 2px solid gold;
  animation: circle 1.5s infinite;
}
.factoryHead {
  background-color: var(--orange);
  color: white;
}
.emptyCell {
  background-color: var(--sheetEmptyCell);
}
