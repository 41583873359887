.RecipeAddWindow {
  top: 20vh;
  left: 5vw;
  width: 90vw;
  height: 65vh;
}
.RecipeAddWindow form {
  width: 80%;
  height: calc(100% - 20vh);
  background-color: var(--windowTitlebarBg);
  border-radius: 20px;
  margin: 2.5vh auto;
}
.RecipeAddWindow table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
}
.RecipeAddWindow table td {
  text-align: center;
  height: 4vh;
}
.RecipeAddWindow table thead > tr {
  background-color: var(--recipesHeaderbg);
}

.recipeAddInput,
.recipeAddSelect {
  height: 5vh;
  background-color: var(--inputBg);
  color: var(--inputFg);
  border: 1px solid var(--inputBorder);
  padding: 0;
}
.recipeAddInput {
  width: 5vh;
  text-align: center;
}
/* Chrome, Safari, Edge, Opera */
.recipeAddInput::-webkit-outer-spin-button,
.recipeAddInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.recipeAddInput[type="number"] {
  appearance: none;
  -moz-appearance: textfield;
}
.recipeAddSelect {
  width: 10vw;
  margin-left: 5px;
}
.submitRecipe {
  background: radial-gradient(#ffb22c 0%, var(--orange));
  color: white;
  border: none;
  box-shadow: 0 10px 0 #555;
  width: 20vw;
  height: 5vh;
  font-family: "Quicksand";
  font-size: 1.5em;
  font-style: italic;
  position: absolute;
  bottom: 5vh;
  left: 40vw;
  border: 2px solid var(--orangeBorder);
  border-radius: 5px;
}
.submitRecipeAnimated {
  transition: all 0.3s linear;
}
.submitRecipeAnimated:hover {
  box-shadow: 0 5px 0 #555;
  bottom: calc(5vh - 5px);
}
.recipeArrow {
  background: var(--calculatorBackground);
  width: 3em;
  height: 3em;
  border-radius: 50%;
  border-bottom: 5px solid var(--bodyBackground1);
  display: inline-flex;
  background-color: var(--editNodeSlotBg);
  text-align: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.recipeArrow::before,
.recipeArrow::after {
  content: " ";
  position: absolute;
  top: 50%;
  width: 35vw;
  height: 1px;
  background-color: var(--inputBg);
}
.recipeArrow::before {
  left: -35vw;
}
.recipeArrow::after {
  right: -35vw;
}
