.ComputerComponent {
  width: 25vh;
  height: 26vh;
  position: relative;
  margin: 2vh auto 3vh;
}
.computerStand {
  width: 5vw;
  height: 2vh;
  position: absolute;
  bottom: 0vh;
  left: calc(50% - 2.5vw);
  background: var(--calculatorBackground);
  border-radius: 20px;
  box-shadow: 0 0 10px black;
  z-index: 1;
}
.computer {
  width: 25vh;
  height: 25vh;
  background: var(--calculatorBackground);
  border-radius: 20px;
  position: relative;
  box-shadow: 0 0 10px black;
  z-index: 2;
}
.computerDecorationBottom {
  width: 7px;
  height: 7px;
  position: absolute;
  bottom: 1vh;
  right: 3vh;
  background-color: #3fb33f;
  border-radius: 50%;
  box-shadow: 0px 0px 4px #000, 0px 0px 2px #00000080 inset;
}
.computerDecorationBottomAnimated {
  animation: ledBlink 4s linear infinite;
}
.computerDecorationTop {
  width: 25vh;
  position: absolute;
  bottom: 0.5vh;
  left: 0;
  text-align: center;
  font-weight: 900;
  color: var(--calculatorDecorationFg);
  font-family: "Lexend";
  font-style: italic;
  font-size: 0.8em;
}
@keyframes ledBlink {
  0% {
    background-color: #3fb33f;
  }
  20% {
    background-color: #b46c00;
  }
  40% {
    background-color: #3fb33f;
  }
  60% {
    background-color: #b46c00;
  }
  80% {
    background-color: #3fb33f;
  }
  100% {
    background-color: #3fb33f;
  }
}
.computerScreen {
  background: radial-gradient(
    circle,
    rgb(32 124 24) 0%,
    rgba(15, 75, 18, 1) 100%
  );
  width: 20vh;
  height: 20vh;
  position: absolute;
  bottom: 2.5vh;
  left: 2.5vh;
  border-radius: 10px;
  z-index: 3;
  color: var(--calculatorResultFg);
  font-size: 2em;
  text-align: center;
  font-weight: 100;
  overflow: hidden;
  text-shadow: 0 0 10px #e9e681;
  box-shadow: 0 0 10px #000 inset, 0 0 10px #000;
}
.computerScreen::after {
  content: " ";
  display: block;
  width: 20vh;
  height: 20vh;

  --scanlinesColor: #0000001c;
  background: linear-gradient(
    to bottom,
    transparent 0%,
    transparent 16.66%,
    var(--scanlinesColor) 16.66%,
    var(--scanlinesColor) 33.32%,
    transparent 33.32%,
    transparent 49.98%,
    var(--scanlinesColor) 49.98%,
    var(--scanlinesColor) 66.64%,
    transparent 66.64%,
    transparent 83.3%,
    var(--scanlinesColor) 83.3%
  );
  background-size: 100% 10%;
  top: 0;
  position: absolute;
}
.computerScreenAnimated::before {
  content: " ";
  display: block;
  width: 20vh;
  height: 5px;
  background-color: #0000001c;
  top: 0;
  opacity: 1;
  position: absolute;
  animation-name: scanlinesTravel;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-delay: 5s;
}
@keyframes scanlinesTravel {
  0% {
    top: 0;
  }
  25% {
    top: 100%;
    opacity: 1;
  }
  56% {
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 0;
  }
}
.computerScreen img {
  width: 15vh;
  height: 15vh;
  display: block;
  margin: auto;
}
