.CalculatorWindow {
  top: 8.5vh;
  left: 12.5vw;
  width: 75vw;
  height: 87vh;
}
.calculator {
  width: 40vw;
  height: 80vh;
  margin: 1vh auto;
  background: var(--calculatorBackground);
  border-radius: 2em;
  position: relative;
  box-shadow: 0px 0px 10px #000 inset, 0px 5px 0px 1px var(--calculatorBgBox);
}
.calculatorDecorationLeft,
.calculatorDecorationRight {
  width: 2px;
  height: 100%;
  position: absolute;
  top: 0;
  background-color: var(--calculatorDecorationBg);
}
.calculatorDecorationLeft {
  left: 15%;
}
.calculatorDecorationRight {
  right: 15%;
}
.calculatorDecorationCenter {
  width: 38vw;
  height: 76vh;
  position: absolute;
  top: 2vh;
  left: 1vw;
  border: 2px solid var(--calculatorDecorationBg);
  border-radius: 1em;
}
.calculatorDecorationTop {
  width: 15vw;
  /* height: 2em; */
  /* line-height: 2em; */
  position: absolute;
  top: 1vh;
  left: calc(50% - 7.5vw);
  text-align: center;
  font-weight: 900;
  color: var(--calculatorDecorationFg);
  font-family: "Lexend";
  font-style: italic;
  font-size: 1.2em;
  /* writing-mode: tb; */
  /* rotate: 180deg; */
}
.calculatorDecorationBottom {
  width: 7px;
  height: 7px;
  position: absolute;
  bottom: 0.5vh;
  right: 4vh;
  background-color: #3fb33f;
  border-radius: 50%;
  box-shadow: 0px 0px 4px #000, 0px 0px 2px #00000080 inset;
}
.calculatorDecorationBottomAnimated {
  animation: ledBlink 4s linear infinite;
}
@keyframes ledBlink {
  0% {
    background-color: #3fb33f;
  }
  20% {
    background-color: #b46c00;
  }
  40% {
    background-color: #3fb33f;
  }
  60% {
    background-color: #b46c00;
  }
  80% {
    background-color: #3fb33f;
  }
  100% {
    background-color: #3fb33f;
  }
}
.result {
  background-color: var(--calculatorResultBg);
  position: absolute;
  top: 4vh;
  left: 5vw;
  width: 30vw;
  height: 7vh;
  line-height: 7vh;
  border-radius: 0.5em;
  text-align: center;
  font-size: 2em;
  color: var(--calculatorResultFg);
  background-image: linear-gradient(
      var(--calculatorResultBorder) 2px,
      transparent 2px
    ),
    linear-gradient(90deg, var(--calculatorResultBorder) 2px, transparent 2px),
    linear-gradient(var(--calculatorResultBorderAlt) 1px, transparent 1px),
    linear-gradient(
      90deg,
      var(--calculatorResultBorderAlt) 1px,
      transparent 1px
    );
  background-size: 100px 100px, 100px 100px, 20px 20px, 20px 20px;
  background-position: -2px -2px, -2px -2px, -1px -1px, -1px -1px;
}
.resultNextOp {
  position: absolute;
  right: 1vw;
  top: 0;
}
.history,
.quotients {
  color: var(--calculatorPanelFg);
  position: absolute;
  top: 5vh;
  width: 15vw;
  height: 57vh;
}
.history {
  left: 1vw;
  text-align: right;
  padding: 2vh 1vw;
  width: 15vw;
}
.historyDecoration {
  width: 100%;
  margin: 0 auto;
  height: 2vh;
  background-color: #000;
  border-radius: 1em;
}
@keyframes historyReset {
  0% {
    top: -1vh;
  }
  20% {
    top: 20vh;
  }
  40% {
    top: 100vh;
    height: 4em;
  }
  41% {
    height: 0;
  }
  50% {
    top: -1vh;
    height: 0;
  }
  60% {
    height: 0;
  }
  80% {
    top: -1vh;
    height: 1em;
  }
  100% {
    top: -1vh;
    height: 4em;
  }
}
.historyContent {
  background: linear-gradient(
    to bottom,
    var(--calculatorPaperGradient1),
    var(--calculatorPaperGradient2)
  );
  width: 80%;
  max-height: 40vh;
  margin: 0 auto;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  color: var(--calculatorPaperFg);
  text-align: center;
  position: relative;
  top: -1vh;
  font-family: monospace;
  font-size: 1.2em;
  transition: height 0.3s linear;
  overflow: hidden scroll;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
}
.historyContent::-webkit-scrollbar-track {
  background-color: transparent;
}
.historyContentDismiss {
  animation-name: historyReset;
  animation-duration: 2s;
}
.quotients {
  background-color: var(--calculatorPanelBg);
  right: 1vw;
}
.buttons {
  position: absolute;
  top: 13vh;
  left: 2.5vw;
  width: 35vw;
  height: 65vh;
}
.opButtons,
.correctButtons,
.customOpMultiply,
.customOpDivide,
.numberShortcutButtonsBasic,
.numberShortcutButtonsSpeed,
.numberButtons {
  background: var(--calculatorCategoryBackground);
  position: absolute;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  border-radius: 1em;
  border: 2px solid;
  width: 30vw;
  padding: 0 1vw;
  left: 1.25vw;
}
.correctButtons {
  top: 0;
  height: 7vh;
}
.opButtons::after,
.correctButtons::after,
.customOpMultiply::after,
.customOpDivide::after,
.numberShortcutButtonsBasic::after,
.numberShortcutButtonsSpeed::after,
.numberButtons::after {
  background: var(--calculatorCategoryBackground);
  font-family: "Lexend";
  position: absolute;
  left: 1vw;
  top: -0.5em;
  height: 1em;
  padding: 0 1vw;
  font-weight: bold;
  font-size: 0.8em;
  border-radius: 1em;
}
.correctButtons::after {
  content: "Functions";
}
.customOpMultiply::after {
  content: "Splitters";
}
.customOpDivide::after {
  content: "Mergers";
}
.numberShortcutButtonsBasic::after {
  content: "Pipelines";
}
.numberShortcutButtonsSpeed::after {
  content: "Belts";
}
.opButtons::after {
  content: "Operators";
}
.numberButtons::after {
  content: "Numbers";
}
.customOpMultiply {
  top: 8.5vh;
  height: 25vh;
  width: 8vw;
}
.customOpDivide {
  top: 8.5vh;
  height: 25vh;
  width: 8vw;
  left: 12vw;
}
.numberShortcutButtonsBasic .calculatorButton,
.numberShortcutButtonsSpeed .calculatorButton,
.customOpMultiply .calculatorButton,
.customOpDivide .calculatorButton {
  width: 5vw;
}

.numberShortcutButtonsBasic {
  top: 8.5vh;
  left: 23vw;
  height: 10vh;
  width: 8vw;
}
.numberShortcutButtonsSpeed {
  top: 19.5vh;
  left: 23vw;
  width: 8vw;
  height: 14vh;
}
.opButtons {
  bottom: 22.5vh;
  height: 7vh;
}
.numberButtons {
  bottom: 1vh;
  height: 20vh;
}
.calculatorButton {
  display: block;
  width: 7vw;
  height: 4vh;
  line-height: 4vh;
  background: var(--calculatorButtonBg);
  border-radius: 10px;
  text-align: center;
  font-size: 1.4em;
  text-shadow: 1px 1px 0px #c0b7ad;
  font-family: "Lexend";
  box-shadow: -2px -2px 10px #000 inset,
    2px 3px 0px 0px var(--calculatorButtonBoxBg);
  color: var(--calculatorButtonFg);
}
.numberShortcutButtonsBasic .calculatorButton,
.numberShortcutButtonsSpeed .calculatorButton {
  height: 3vh;
  line-height: 3vh;
  font-size: 1.2em;
}
.calculatorOpButton {
  background: var(--calculatorButtonOpBg) !important;
  color: var(--calculatorButtonOpFg);
}
.equalsButton {
  background: var(--orange) !important;
  color: white;
}
.calculatorCorrectButton {
  background: var(--calculatorButtonOpBg) !important;
  color: var(--calculatorButtonOpFg);
}
.calculatorCustomOpButton {
  background: var(--calculatorCustomOpBg) !important;
  color: white;
}
.quotientItem {
  width: 80%;
  height: 10vh;
  margin: 1vh auto;
  border: 1px solid var(--calculatorPanelFg);
  color: var(--calculatorPanelFg);
}
.quotientItem > span {
  display: block;
  width: 50%;
  margin: auto;
  text-align: center;
}
.quotientItemBase {
  border-bottom: 1px solid var(--calculatorPanelFg);
}
.helpContainer {
  width: 15vw;
  height: 30vh;
  background: linear-gradient(to bottom right, #ffee52, #b8ad51);
  color: #000;
  position: absolute;
  bottom: 1vh;
  left: 1vw;
  overflow: hidden;
  z-index: 2;
}
.helpContainerDecoration {
  display: block;
  width: 2vw;
  height: 5vh;
  position: absolute;
  bottom: 29.5vh;
  left: 7.5vw;
  rotate: 12deg;
  background-color: #dddddd8c;
  box-shadow: 0 0 5px #000;
  z-index: 9;
}
.helpContainer table {
  height: 90%;
  margin-top: 10%;
  width: 100%;
  text-align: center;
}
