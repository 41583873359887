.codexTableImage {
  width: 75px;
  height: 75px;
}
.codexTable {
  width: 90%;
  margin: auto;
  border-collapse: collapse;
  border: 1px solid #999;
  text-align: center;
  margin-bottom: 2vh;
}

.codexTable tbody tr:nth-child(odd) {
  background-color: var(--recipesOddBg);
}
.codexTable tbody tr:nth-child(even) {
  background-color: var(--recipesEvenBg);
}
.codexTable thead tr {
  background-color: var(--recipesHeaderBg);
}
.codexP {
  width: 90%;
  margin: 2vh auto;
  padding: 2em;
  background-color: var(--recipesOddBg);
  border-radius: 2em;
}
.codexItemList {
  margin: 1vh auto;
}
.itemListItem {
  display: block;
  margin: 0.2vh auto;
}
.codexTable a,
.itemListItem > a {
  color: var(--orange);
}
.codexEntry {
  background: var(--codexEntryBg);
  padding: 2em;
  border-top-right-radius: 20px;
  border-left: 2px solid;
  width: 90%;
  margin-left: calc(5% - 2px);
}
